<template>
	<div>
		<a href="javascript:history.back()" class="btn btn-outline-secondary mr-2 mb-2" v-if="showBackLink">
			<font-awesome-icon icon="arrow-left"/>
			{{ $t('wizard.prev') }}
		</a>

		<h1>Adatkezel&eacute;si t&aacute;j&eacute;koztat&oacute;</h1>

		<h1>1. Adatkezelő adatai</h1>

		<table>
			<tbody>
			<tr>
				<td>Adatkezelő megnevez&eacute;se:</td>
				<td><strong>Ready 2 Grow Kereskedelmi &eacute;s Szolg&aacute;ltat&oacute; Korl&aacute;tolt Felelőss&eacute;gű T&aacute;rsas&aacute;g</strong></td>
			</tr>
			<tr>
				<td>Sz&eacute;khely:</td>
				<td><strong>2100 G&ouml;d&ouml;llő, Remsey krt. 8.</strong></td>
			</tr>
			<tr>
				<td>Telefon:</td>
				<td><a href="tel:+36303715660"><strong>+36 30 371 5660</strong></a></td>
			</tr>
			<tr>
				<td>Email:</td>
				<td><a href="mailto:info@r2g.hu"><strong>info@r2g.hu</strong></a></td>
			</tr>
			<tr>
				<td>Honlap:</td>
				<td><a href="https://www.r2g.hu" target="_blank"><strong>www.r2g.hu</strong></a></td>
			</tr>
			<tr>
				<td>C&eacute;gjegyz&eacute;k sz&aacute;m:</td>
				<td><strong>13-09-177610</strong></td>
			</tr>
			<tr>
				<td>K&eacute;pviselője:</td>
				<td><strong>Maka Marianna</strong></td>
			</tr>
			</tbody>
		</table>

		<p>&nbsp;</p>

		<h1>2. A t&aacute;j&eacute;koztat&oacute; c&eacute;lja</h1>

		<p>Jelen adatkezel&eacute;si t&aacute;j&eacute;koztat&oacute; c&eacute;lja, hogy az &eacute;rintettek sz&aacute;m&aacute;ra k&ouml;z&eacute;rthető form&aacute;ban &eacute;s &aacute;ttekinthető m&oacute;don inform&aacute;ci&oacute;t biztos&iacute;tson szem&eacute;lyes adataik kezel&eacute;s&eacute;vel kapcsolatban.</p>

		<p>Az adatkezel&eacute;s nem tartozik fő tev&eacute;kenys&eacute;geink sor&aacute;ba, de annak vitel&eacute;ben k&uuml;l&ouml;n&ouml;s tekintettel vagyunk a vonatkoz&oacute; eur&oacute;pai uni&oacute;s &eacute;s hazai jogi szab&aacute;lyoz&aacute;sra, k&uuml;l&ouml;n&ouml;sk&eacute;ppen az Eur&oacute;pai Parlament &eacute;s a Tan&aacute;cs (EU) 2016/679 rendelete (2016. &aacute;prilis 27.) &bdquo;A term&eacute;szetes szem&eacute;lyeknek a szem&eacute;lyes adatok kezel&eacute;se tekintet&eacute;ben t&ouml;rt&eacute;nő v&eacute;delm&eacute;ről &eacute;s az ilyen adatok szabad &aacute;raml&aacute;s&aacute;r&oacute;l, valamint a 95/46/EK rendelet hat&aacute;lyon k&iacute;v&uuml;l helyez&eacute;s&eacute;ről (&aacute;ltal&aacute;nos adatkezel&eacute;si rendelet - GDPR)&rdquo; valamint a hazai &bdquo;Az inform&aacute;ci&oacute;s &ouml;nrendelkez&eacute;si jogr&oacute;l &eacute;s az inform&aacute;ci&oacute;szabads&aacute;gr&oacute;l&rdquo; sz&oacute;l&oacute; 2011. &eacute;vi CXII. t&ouml;rv&eacute;ny (Infotv.) elő&iacute;r&aacute;saira.</p>

		<p>Szakmai tev&eacute;kenys&eacute;g&uuml;nket illetően c&eacute;g&uuml;nk t&eacute;rk&eacute;p alapon a l&eacute;giforgalom megjelen&iacute;t&eacute;s&eacute;vel &eacute;s k&ouml;vet&eacute;s&eacute;vel, valamint GPS alap&uacute; rep&uuml;l&eacute;si inform&aacute;ci&oacute;k szolg&aacute;ltat&aacute;s&aacute;val foglalkozik, a PilotNet mobil vagy webes applik&aacute;ci&oacute;j&aacute;nak felhaszn&aacute;l&oacute;i sz&aacute;m&aacute;ra (tov&aacute;bbiakban &ldquo;Szolg&aacute;ltat&aacute;sok&rdquo;).</p>

		<h1>3. Kezelt adatok</h1>

		<p>Az eszk&ouml;z t&iacute;pus&aacute;t&oacute;l f&uuml;ggően amire a &ldquo;Szolg&aacute;ltat&aacute;s&rdquo; telep&iacute;t&eacute;sre ker&uuml;lt, a PilotNet applik&aacute;ci&oacute; k&uuml;l&ouml;nb&ouml;ző jogosults&aacute;gokat k&eacute;rhet az felhaszn&aacute;l&oacute; eszk&ouml;z&eacute;n.</p>

		<p>Ezen jogosults&aacute;gok megad&aacute;sa k&ouml;telező az applik&aacute;ci&oacute; műk&ouml;d&eacute;s&eacute;nek biztos&iacute;t&aacute;s&aacute;hoz. A jogosults&aacute;gokat a felhaszn&aacute;l&oacute; b&aacute;rmikor elveheti az adott eszk&ouml;z be&aacute;ll&iacute;t&aacute;si &uacute;tmutat&oacute;j&aacute;nak &eacute;s haszn&aacute;lati utas&iacute;t&aacute;s&aacute;nak megfelelően.</p>

		<p>A jogosults&aacute;gok kezel&eacute;se eszk&ouml;zf&uuml;ggő, &eacute;s emiatt elt&eacute;rő lehet.</p>

		<p>A &ldquo;Szolg&aacute;ltat&aacute;s&rdquo; haszn&aacute;lata eset&eacute;n a felhaszn&aacute;l&aacute;s m&oacute;dj&aacute;t&oacute;l f&uuml;ggően az applik&aacute;ci&oacute; hozz&aacute;f&eacute;r&eacute;st k&eacute;rhet a felhaszn&aacute;l&oacute; email c&iacute;m&eacute;hez, felhaszn&aacute;l&oacute; applik&aacute;ci&oacute;ban megadott adataihoz, a haszn&aacute;lt eszk&ouml;z pontos helyzet&eacute;hez, rep&uuml;l&eacute;si &uacute;tvonalhoz, rep&uuml;l&eacute;s adataihoz, az eszk&ouml;z IP c&iacute;m&eacute;hez &eacute;s egy&eacute;b eszk&ouml;z &aacute;ltal szolg&aacute;ltatott inform&aacute;ci&oacute;khoz &eacute;s ezen inform&aacute;ci&oacute;k t&aacute;rol&aacute;sra ker&uuml;lhetnek a t&aacute;rolt &uacute;tvonalhoz kapcsoltan.</p>

		<p>A rep&uuml;l&eacute;sek, melyek sor&aacute;n a felhaszn&aacute;l&oacute; a &ldquo;Szolg&aacute;ltat&aacute;st&rdquo; haszn&aacute;lta, r&ouml;gz&iacute;t&eacute;sre ker&uuml;l a rendszerben.</p>

		<p>A PilotNet applik&aacute;ci&oacute; r&ouml;gz&iacute;ti az inform&aacute;ci&oacute;kat amiknek megoszt&aacute;s&aacute;hoz hozz&aacute;j&aacute;rult a felhaszn&aacute;l&oacute;, mint p&eacute;ld&aacute;ul:</p>

		<ul>
			<li>Telefonsz&aacute;m, rep&uuml;lőg&eacute;p adatok, &uacute;tvonaltervek &eacute;s c&eacute;lrep&uuml;lőterek, rep&uuml;l&eacute;sek időpontja</li>
			<li>A felhaszn&aacute;l&oacute; pil&oacute;tak&eacute;nt vagy utask&eacute;nt v&eacute;gzett utaz&aacute;sait</li>
			<li>Tov&aacute;bb&aacute; olyan inform&aacute;ci&oacute;kat, amiket a felhaszn&aacute;l&oacute; a PilotNet supporttal megoszt</li>
		</ul>

		<p><strong>Meta adatok (eszk&ouml;z-, b&ouml;ng&eacute;sző- &eacute;s az applik&aacute;ci&oacute; haszn&aacute;lat&aacute;nak adatai)</strong></p>

		<p>A PilotNet applik&aacute;ci&oacute; adatokat gyűjthet &eacute;s r&ouml;gz&iacute;thet a &ldquo;Szolg&aacute;ltat&aacute;s&rdquo; haszn&aacute;lat&aacute;r&oacute;l, az eszk&ouml;zről, amelyre az applik&aacute;ci&oacute; telep&iacute;t&eacute;sre ker&uuml;lt &eacute;s a b&ouml;ng&eacute;szőről, amelyen a &ldquo;Szolg&aacute;ltat&aacute;st&rdquo; haszn&aacute;lj&aacute;k. P&eacute;ld&aacute;ul:</p>

		<ul>
			<li>hogy milyen gyakran, &eacute;s mennyi ideig van a &ldquo;Szolg&aacute;ltat&aacute;s&rdquo; haszn&aacute;latban, a haszn&aacute;lt eszk&ouml;z t&iacute;pus&aacute;r&oacute;l, az oper&aacute;ci&oacute;s rendszer t&iacute;pus&aacute;r&oacute;l &eacute;s verzi&oacute;j&aacute;r&oacute;l, az akkumul&aacute;tor haszn&aacute;lat&aacute;r&oacute;l, a kiszolg&aacute;l&oacute; domain &eacute;s IP c&iacute;m&eacute;ről amelyen kereszt&uuml;l a &ldquo;Szolg&aacute;ltat&aacute;st&rdquo; haszn&aacute;lj&aacute;k, a haszn&aacute;lt eszk&ouml;z pontos helyadatairől</li>
			<li>A felhaszn&aacute;l&oacute; eszk&ouml;ze &eacute;s a &ldquo;Szolg&aacute;ltat&aacute;s&rdquo; k&ouml;z&ouml;tti adatforgalomr&oacute;l, bele&eacute;rtve a crash riportokat, amelyek tartalmazhatj&aacute;k az eszk&ouml;z teljes&iacute;tm&eacute;ny &eacute;s haszn&aacute;lati adatait időpontokkal ell&aacute;tva</li>
		</ul>

		<h1>4. Az adatgyűjt&eacute;s &aacute;ltal&aacute;nos c&eacute;lja</h1>

		<p>A PilotNet applik&aacute;ci&oacute; adatgyűjt&eacute;s&eacute;nek &eacute;s adatfeldolgoz&aacute;s&aacute;nak c&eacute;lja:</p>

		<ul>
			<li>A &ldquo;Szolg&aacute;ltat&aacute;s&rdquo; biztos&iacute;t&aacute;sa, p&eacute;ld&aacute;ul l&eacute;gi forgalom megjelen&iacute;t&eacute;se &eacute;s a Situational Awareness seg&iacute;t&eacute;se</li>
			<li>A &ldquo;Szolg&aacute;ltat&aacute;s&rdquo; sz&iacute;nvonal&aacute;nak &eacute;s minős&eacute;g&eacute;nek biztos&iacute;t&aacute;sa, fenntart&aacute;sa &eacute;s fejleszt&eacute;se, &uacute;j funkci&oacute;k implement&aacute;l&aacute;sa a begyűjt&ouml;tt adatok &eacute;s visszajelz&eacute;sek alapj&aacute;n</li>
			<li>&Uacute;j &ldquo;Szolg&aacute;ltat&aacute;sok&rdquo; fejleszt&eacute;se</li>
			<li>A &ldquo;Szolg&aacute;ltat&aacute;s&rdquo; haszn&aacute;lati m&oacute;djainak felt&eacute;rk&eacute;pez&eacute;se, melynek alapj&aacute;n a &ldquo;Szolg&aacute;ltat&aacute;s&rdquo; jobb&aacute; t&eacute;tele lehets&eacute;gess&eacute; v&aacute;lhat</li>
		</ul>

		<h1>5. Az adatok biztons&aacute;ga</h1>

		<p>Technikai &eacute;s szervez&eacute;si int&eacute;zked&eacute;sekkel, valamint elj&aacute;r&aacute;sok kialak&iacute;t&aacute;s&aacute;val gondoskodunk az &aacute;ltalunk kezelt szem&eacute;lyes adatok biztons&aacute;g&aacute;r&oacute;l.</p>

		<p>Az adatokat megfelelő int&eacute;zked&eacute;sekkel v&eacute;dj&uuml;k a jogosulatlan hozz&aacute;f&eacute;r&eacute;s, megv&aacute;ltoztat&aacute;s, tov&aacute;bb&iacute;t&aacute;s, nyilv&aacute;noss&aacute;gra hozatal, t&ouml;rl&eacute;s vagy megsemmis&iacute;t&eacute;s, valamint a v&eacute;letlen megsemmis&uuml;l&eacute;s &eacute;s s&eacute;r&uuml;l&eacute;s, tov&aacute;bb&aacute; az alkalmazott technika megv&aacute;ltoz&aacute;s&aacute;b&oacute;l fakad&oacute; hozz&aacute;f&eacute;rhetetlenn&eacute; v&aacute;l&aacute;s ellen.</p>

		<p>A szem&eacute;lyes adatokhoz csak azok a munkat&aacute;rsaink f&eacute;rnek hozz&aacute;, akiknek feladataik ell&aacute;t&aacute;s&aacute;hoz sz&uuml;ks&eacute;g&uuml;k van azok megismer&eacute;s&eacute;re.</p>

		<p>Az adatok biztons&aacute;ga &eacute;rdek&eacute;ben</p>

		<ul>
			<li>az informatikai rendszer tervez&eacute;se &eacute;s &uuml;zemeltet&eacute;se sor&aacute;n felm&eacute;rj&uuml;k &eacute;s figyelembe vessz&uuml;k a lehets&eacute;ges kock&aacute;zatokat, t&ouml;rekedve azok folyamatos cs&ouml;kkent&eacute;s&eacute;re</li>
			<li>figyelemmel k&iacute;s&eacute;rj&uuml;k a felmer&uuml;lő fenyeget&eacute;seket &eacute;s s&eacute;r&uuml;l&eacute;kenys&eacute;geket (mint pl. sz&aacute;m&iacute;t&oacute;g&eacute;pes v&iacute;rusok, sz&aacute;m&iacute;t&oacute;g&eacute;pes bet&ouml;r&eacute;sek, szolg&aacute;ltat&aacute;smegtagad&aacute;sra vezető t&aacute;mad&aacute;sok stb.), hogy időben int&eacute;zkedhess&uuml;nk azok elker&uuml;l&eacute;se, elh&aacute;r&iacute;t&aacute;sa &eacute;rdek&eacute;ben</li>
			<li>az informatikai eszk&ouml;z&ouml;ket, valamint a pap&iacute;ron kezelt inform&aacute;ci&oacute;kat v&eacute;dj&uuml;k az illet&eacute;ktelen fizikai hozz&aacute;f&eacute;r&eacute;s, valamint a k&ouml;rnyezeti hat&aacute;sok (pl. v&iacute;z, tűz, elektromos t&uacute;lfesz&uuml;lts&eacute;g) ellen</li>
			<li>az informatikai rendszer&uuml;nk megfigyel&eacute;s&eacute;vel gondoskodunk a lehets&eacute;ges probl&eacute;m&aacute;k, esem&eacute;nyek felder&iacute;t&eacute;s&eacute;ről</li>
			<li>nagy gondot ford&iacute;tunk a munkat&aacute;rsak inform&aacute;ci&oacute;biztons&aacute;gi k&eacute;pz&eacute;s&eacute;re &eacute;s a tudatoss&aacute;g n&ouml;vel&eacute;s&eacute;re</li>
			<li>az &uuml;zemeltet&eacute;sben r&eacute;sztvevő szolg&aacute;ltat&oacute;k kiv&aacute;laszt&aacute;s&aacute;n&aacute;l alapvető szempont a megb&iacute;zhat&oacute;s&aacute;g</li>
		</ul>

		<h1>6. Az adatok tov&aacute;bb&iacute;t&aacute;sa, &aacute;tad&aacute;sa</h1>

		<p>Az adatok &aacute;tad&aacute;s&aacute;ra sor ker&uuml;lhet hat&oacute;s&aacute;gi megkeres&eacute;sek eset&eacute;n.</p>

		<p>A PilotNet &ldquo;Szolg&aacute;ltat&aacute;s&rdquo; a t&aacute;rolt rep&uuml;l&eacute;sek adatait anon&iacute;m m&oacute;don tov&aacute;bb&iacute;thatja &eacute;s &eacute;rt&eacute;kes&iacute;theti harmadik f&eacute;l sz&aacute;m&aacute;ra a kisg&eacute;pes rep&uuml;l&eacute;s fejlőd&eacute;se &eacute;rdek&eacute;ben. Ilyen adatok lehetnek a rep&uuml;lt &uacute;tvonalak adatai, rep&uuml;l&eacute;sek gyakoris&aacute;ga, rep&uuml;lőg&eacute;p t&iacute;pusa, stb.</p>

		<p>A PilotNet &quot;Szolg&aacute;ltat&aacute;s&quot; a felhaszn&aacute;l&aacute;si adatokat anonim m&oacute;don, analitika &eacute;s felhaszn&aacute;l&aacute;si m&oacute;dok monitoroz&aacute;sa c&eacute;lj&aacute;b&oacute;l tov&aacute;bb&iacute;thatja a Google fel&eacute;. (<a href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a>)</p>

		<h1>7. Szem&eacute;lyes adatok megőrz&eacute;s&eacute;nek ideje</h1>

		<p>A PilotNet a szem&eacute;lyes adatokat a &ldquo;Szolg&aacute;ltat&aacute;s&rdquo; haszn&aacute;lat&aacute;nak időtartama alatt t&aacute;rolja. Onnant&oacute;l, hogy a felhaszn&aacute;l&oacute; t&ouml;bb&eacute; nem haszn&aacute;lja a &ldquo;Szolg&aacute;ltat&aacute;st&rdquo;, adatai 5 &eacute;vig t&aacute;rolva maradhatnak.</p>

		<h1>8. Az &eacute;rintettek jogai</h1>

		<p>&Eacute;rintettek: minden term&eacute;szetes szem&eacute;ly, aki az applik&aacute;ci&oacute;t let&ouml;lti, haszn&aacute;lja.</p>

		<p>Minden &eacute;rintettnek joga van:</p>

		<ul>
			<li>Az őt &eacute;rintő adatkezel&eacute;sről annak megkezd&eacute;s&eacute;t megelőzően t&aacute;j&eacute;koztat&aacute;st kapjon</li>
			<li>Hozz&aacute;f&eacute;rjen az őt &eacute;rintő adatkezel&eacute;ssel kapcsolatos minden inform&aacute;ci&oacute;hoz</li>
			<li>Hib&aacute;s, pontatlan, hi&aacute;nyos adatainak helyesb&iacute;t&eacute;s&eacute;t k&eacute;rni</li>
			<li>Szem&eacute;lyes adatainak t&ouml;rl&eacute;s&eacute;t (inaktiv&aacute;l&aacute;s&aacute;t) k&eacute;rni</li>
			<li>Az adatkezel&eacute;s korl&aacute;toz&aacute;s&aacute;t k&eacute;rni</li>
			<li>Tiltakozni adatainak bizonyos esetekben t&ouml;rt&eacute;nő, pl. marketing c&eacute;l&uacute; felhaszn&aacute;l&aacute;sa ellen</li>
			<li>Jogorvoslattal &eacute;lni az adatkezel&eacute;ssel szemben</li>
		</ul>

		<p>Ezeket a jogokat az &eacute;rintettek az al&aacute;bb megadott el&eacute;rhetős&eacute;geink haszn&aacute;lat&aacute;val, &iacute;r&aacute;sban, illetve előzetes egyeztet&eacute;s alapj&aacute;n szem&eacute;lyesen gyakorolhatj&aacute;k. Minden megkeres&eacute;sre igyeksz&uuml;nk a lehető legr&ouml;videbb időn bel&uuml;l, de legfeljebb 15 munkanapon bel&uuml;l v&aacute;laszolni.</p>

		<p>El&eacute;rhetős&eacute;gek a joggyakorl&aacute;s &eacute;rdek&eacute;ben:</p>

		<ul>
			<li>Postai lev&eacute;lben: Ready 2 Grow Kereskedelmi &eacute;s Szolg&aacute;ltat&oacute; Korl&aacute;tolt Felelőss&eacute;gű T&aacute;rsas&aacute;g, 2100 G&ouml;d&ouml;llő, Remsey krt. 8.</li>
			<li>E-mailben: <a href="mailto:info@pilotnet.hu">info@pilotnet.hu</a></li>
			<li>Szem&eacute;lyesen: <a href="tel:+36303715660">+36 30 371 5660</a> telefonsz&aacute;mon egyeztetett m&oacute;don.</li>
		</ul>

		<p>Telefonon szem&eacute;lyes adatokkal kapcsolatos inform&aacute;ci&oacute;kat nem &aacute;ll m&oacute;dunkban kiadni, mivel nem tudjuk azonos&iacute;tani a h&iacute;v&oacute; felet.</p>

		<p>Jogaik megs&eacute;rt&eacute;se eset&eacute;n az &eacute;rintettek a Nemzeti Adatkezel&eacute;si &eacute;s Inform&aacute;ci&oacute;szabads&aacute;g Hat&oacute;s&aacute;ghoz fordulhatnak</p>

		<ul>
			<li>C&iacute;m: 1125 Budapest, Szil&aacute;gyi Erzs&eacute;bet fasor 22/c</li>
			<li>Telefon: +36 (1) 391-1400</li>
			<li>Fax: +36 (1) 391-1410</li>
			<li>honlap: <a href="http://www.naih.hu" target="_blank">http://www.naih.hu</a></li>
		</ul>
	</div>
</template>

<script>
export default {
	name: "PrivacyStatementView",
	data() {
		return {
			showBackLink: history.length > 1
		}
	},
	methods: {
		scrollTo(refName) {
			let element = this.$refs[refName];
			let top = element.offsetTop;
			window.scrollTo(0, top);
		}
	}
}
</script>

<style scoped>
table {
	border-top: 1px solid #000000;
	border-left: 1px solid #000000;
	border-spacing: 0;
	width: 100%;
	margin-bottom: 1rem;
}

td, th {
	border-bottom: 1px solid #000000;
	border-right: 1px solid #000000;
	padding: 5px;
	text-align: left;
}

tbody th {
	font-weight: normal;
}

ol {
	counter-reset: item;
}

ol > li {
	counter-increment: item;
}

ol ol > li {
	display: block;
}

ol ol > li:before {
	content: counters(item, ".") ". ";
	margin-left: -20px;
}
</style>
